<template>
    <div>
        <div v-if="authUserPermission['mrp-controller-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col-7">
                                <h3 class="mb-0">{{ tt('mrp_controller') }}</h3>
                            </div>
                            <div class="col-5">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search_mrp_controller')" v-on:keyup="filter"/>                            
                                </div>
                            </div>
                            <!-- <div class="col text-right">
                                <base-button size="sm" type="default" @click="create" v-if="authUserPermission['catalog-status-create']">{{ tt('add_new') }}</base-button>
                            </div> -->
                        </div>
                    </div>
                    <div>
                        <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                            <el-table-column :label="tt('plant_code')" :prop="tt('plant_code')" sortable>
                                <template v-slot="{row}">
                                    {{row.plant_code}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('code')" :prop="tt('code')" sortable>
                                <template v-slot="{row}">
                                    {{row.mrp_controller_code}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('description')" :prop="tt('description')" sortable>
                                <template v-slot="{row}">
                                    {{row.mrp_controller_desc}}
                                </template>
                            </el-table-column>
                            <!-- <el-table-column prop="action" width="100">
                                <template v-slot="{row}">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['catalog-status-update'] || authUserPermission['catalog-status-delete']">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                            <el-dropdown-item :command="{action:'edit', data:row.id}" v-if="authUserPermission['catalog-status-update']">{{ tt('edit') }}</el-dropdown-item>
                                            <el-dropdown-item :command="{action:'remove', data:row.id}" v-if="authUserPermission['catalog-status-delete']">{{ tt('delete') }}</el-dropdown-item>                                        
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column> -->
                        </el-table>
                        <skeleton-loading v-else/>
                    </div>                 
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>
            <!-- <validation-observer v-slot={invalid}>
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>

                        <label class="form-control-label">{{ tt('type') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('type')" rules="required">
                            <el-select class="select-danger" v-model="catalogStatus.type" :placeholder="tt('choose_type')">
                                <el-option class="select-danger" :value="dt['value']" :label="dt['text']" :key="dt['text']" v-for="dt in dropdown_type">{{ dt['text'] }}</el-option>
                            </el-select>
                        </base-input>

                        <label class="form-control-label">{{ tt('code') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('code')" :placeholder="tt('code')" v-model="catalogStatus.code" rules="required"></base-input>

                        <label class="form-control-label">{{ tt('description') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('description')" :placeholder="tt('description')" v-model="catalogStatus.description" rules="required"></base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer> -->
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import mrpController from '@/services/master/mrpController.service';

    export default {        
        data() {
            return {          
                onLoad: true,      
                loadTimeout: null,            
                table: {
                    search: (this.$route.query.search) ? this.$route.query.search : '',                    
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, mrpController.get(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data; 
                    context.table.page  = response.data.data.data;   
                    context.onLoad = false;          
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                })
                .call()
            },
            filter() {
                if(this.table.search != this.$route.query.search){
                    this.onLoad = true;
                    this.table.page.current_page = 1;
                    clearTimeout(this.loadTimeout);
                    this.loadTimeout = setTimeout(() => {
                        if(this.table.search == ''){
                            this.$router.push({path:'/master/mrp-controller', query:null})
                        }else{
                            this.$router.push({ path: '/master/mrp-controller', query:{search: this.table.search}})
                        } 
                        this.get()
                    }, 100);
                }
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
        }   
    };
</script>
<style></style>
